import React, { useEffect, useState } from 'react';

import * as style from 'styles/components/account/accountLayout.module.scss';
// import { LangContext } from 'context/LangContext';
import { useAuth } from 'context/UserContext';
import fetchAPI from 'data/api';
import AccountLayout from 'components/account/Layout';
import ListOffers from 'components/account/ListOffers';
import ListMonthlyOffers from 'components/account/ListOffersMonthly';
// import MembershipEmailRegister from 'components/account/MembershipEmailRegister';
import Loader from 'components/utils/Loader';
// import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

import bajenDealen from '../../images/bajendealen.png';

const AccountMembershipOffers = ({ pageContext }) => {
    // const { lang } = useContext(LangContext);
    const { isSignedIn, user } = useAuth();

    const [allMemberships, setAllMemberships] = useState([]);
    const [isNoMembership, setIsNoMembership] = useState(null);

    // const [isErrorLoadingMemberships, setIsErrorLoadingMemberships] = useState(false);

    const [allMembershipOffers, setAllMemberShipOffers] = useState([]);
    const [monthlyMembershipOffers, setMonthlyMembershipOffers] = useState([]);
    const [filteredMembershipOffers, setFilteredMembershipOffers] = useState([]);
    const [isNoMembershipOffers, setIsNoMembershipOffers] = useState(null);

    const [allMembershipOffersCategories, setAllMembershipOffersCategories] = useState([]);
    const [allMembershipOffersCompanies, setAllMembershipOffersCompanies] = useState([]);

    const [loading, setLoading] = useState(false);

    function loopAllMembershipOffersCompanies (allMembershipOffersArr) {
        const companies = [];
        allMembershipOffersArr.forEach((offer) => {
            if( offer.company ) {
                companies.push(offer.company.name);
            }
        });

        setAllMembershipOffersCompanies(companies);
    };
    useEffect(() => {
        if (!isSignedIn) return;
        // The aws api will check for memberships based on cognito info in this order
        // 1. custom:membershipEmail
        // 2. email
        // We explicit check for custom:membershipEmail to avoid any issues
        if(!user.attributes['custom:membershipEmail']) return;
        setLoading(true);

        fetchAPI(process.env.GATSBY_API_MEMBERSHIPS, user?.signInUserSession.idToken.jwtToken)
            .then((memberships) => {
                // const availableMemberships = memberships;
                const queueArray = Array.isArray(memberships) ? memberships : [memberships];
                const availableSeasonPassQueueData = queueArray
                    .map(memberData => ({
                        ...memberData,
                    }));

                setAllMemberships(availableSeasonPassQueueData[0]);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                // setIsErrorLoadingMemberships(true);
                setLoading(false);
            });
    }, [isSignedIn], pageContext);

    useEffect(() => {
        if (!isSignedIn) return;

        setLoading(true);

        fetchAPI(process.env.GATSBY_API_MEMBERSHIP_OFFERS, user?.signInUserSession.idToken.jwtToken)
            .then(offers => {
                const availableOffers = Array.isArray(offers) ? offers : [offers];

                const noPartnersOffer = availableOffers[0].offers.filter((offer) => {
                    if(offer.targetAudience === 'partner') {
                        return null;
                    }
                    return offer;
                });
                setAllMemberShipOffers(noPartnersOffer);
                setFilteredMembershipOffers(noPartnersOffer);
                loopAllMembershipOffersCompanies(noPartnersOffer);

                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }, [isSignedIn, pageContext]);

    useEffect(() => {
        if (!isSignedIn) return;

        fetchAPI(process.env.GATSBY_API_MEMBERSHIP_OFFERS_CATEGORIES, user?.signInUserSession.idToken.jwtToken)
            .then(categories => {
                const availableCategories = Array.isArray(categories) ? categories : [categories];
                setAllMembershipOffersCategories(availableCategories);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                // setIsErrorLoadingSeasonPassQueue(true);
                setLoading(false);
            });
    }, [isSignedIn, pageContext]);

    useEffect(() => {
        if (!allMembershipOffers) return;

        if (allMembershipOffers.length === 0) {
            setIsNoMembershipOffers(true);
            return;
        }
        setIsNoMembershipOffers(false);

    }, [allMembershipOffersCompanies, allMembershipOffers]);

    useEffect(() => {
        if (!allMembershipOffersCategories) return;

        if (allMembershipOffersCategories.length === 0) {
            setIsNoMembershipOffers(true);
            return;
        }

        setIsNoMembershipOffers(false);

    }, [allMembershipOffersCategories]);

    useEffect(() => {
        if (!allMembershipOffers) return;

        if (allMembershipOffers.length === 0) {
            setIsNoMembershipOffers(true);
            return;
        }

        setIsNoMembershipOffers(false);

    }, [allMembershipOffers]);

    useEffect(() => {
        if (!allMemberships) return;

        if (allMemberships.length === 0 || allMemberships.status === 400 || allMemberships.membershipStatus !== 20) {
            setIsNoMembership(true);
            return;
        }

        setIsNoMembership(false);

    }, [allMemberships]);

    useEffect(() => {
        const monthly = allMembershipOffers.filter((offer) => {
            if(offer.isPinned) {
                return offer;
            }
            return null;
        });
        setMonthlyMembershipOffers(monthly);
    }, [allMembershipOffers]);

    const handleCategoryChange = (evt) => {
        document.getElementById("allCompanies").selectedIndex = 0;
        const filteredOffers = [];
        const selectedCategory = evt.target.value;

        if (selectedCategory !== 'all_categories') {

            allMembershipOffers.forEach((offer) => {
                if(parseInt(offer.category.id, 10) === parseInt(selectedCategory, 10)) {
                    filteredOffers.push(offer);
                }
            });
            setFilteredMembershipOffers(filteredOffers);
        } else {
            setFilteredMembershipOffers(allMembershipOffers);
        }

    };

    const onHandleCompanyChange = (evt) => {
        document.getElementById("allCategories").selectedIndex = 0;

        const filteredOffers = [];
        const selectedCompany = evt.target.value;

        if (selectedCompany !== 'all_companies') {

            allMembershipOffers.forEach((offer) => {
                if(offer.company && offer.company.name === selectedCompany){
                    filteredOffers.push(offer);
                }
            });
            setFilteredMembershipOffers(filteredOffers);
        } else {
            setFilteredMembershipOffers(allMembershipOffers);
        }
    };

    return (
        <AccountLayout pageContext={pageContext}>
            {isSignedIn && (
                <>
                    {loading && <Loader message="Laddar" />}

                    {allMemberships.membershipStatus && allMemberships.membershipStatus === 20 &&
                        <div className={style.account__logoTitle}>
                            <img src={bajenDealen} alt="BajenDealen" />
                        </div>
                    }

                    {allMemberships.membershipStatus && allMemberships.membershipStatus === 20 && allMemberships.memberCards.length === 0 && (
                        <div className={style.account__errorTicketsBox}>
                            <p>
                                <span>Ett fel uppstod vid inläsningen av ditt medlemskap. Försök igen senare.</span>
                            </p>
                        </div>
                    )}

                    {isNoMembershipOffers && (
                        <div className={style.account__emptyTicketsBox}>
                            &nbsp;
                        </div>
                    )}

                        <div className={style.account__filterForm}>
                            <form method="POST" >
                                <div className={style.account__picker}>
                                    {allMembershipOffersCategories.length > 0 && (
                                        <div className={`${style.account__pickerBox} form-group`}>
                                            <select name="allCategories" id="allCategories" onChange={handleCategoryChange}>
                                                <option key="blbl" value="all_categories">
                                                    Alla kategorier
                                                </option>
                                            {allMembershipOffersCategories.map((item, index) => (
                                                <option key={index} value={item.id}>
                                                    {item.name}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                    )}

                                    {allMembershipOffersCompanies.length > 0 && (
                                        <div className={`${style.account__pickerBox} form-group`}>
                                            <select name="allCompanies" id="allCompanies" onChange={onHandleCompanyChange}>
                                                <option key="blbl" value="all_companies">
                                                    Alla företag
                                                </option>
                                            {allMembershipOffersCompanies.map((item, index) => (
                                                <option key={index} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                            </select>
                                        </div>
                                    )}
                                </div>
                            </form>
                        </div>

                        <ListMonthlyOffers offers={monthlyMembershipOffers} />

                        <ListOffers offers={filteredMembershipOffers} />

                        {isNoMembership && (
                            <div className="test" />
                        )}
                </>
            )}
        </AccountLayout>
    );
};

export default AccountMembershipOffers;
